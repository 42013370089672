<template>
  <div class="all-height all-width">
    <div class="">
      <v-card loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <div class="d-flex mb-4">
              <div class="d-flex align-center">
                <span class="display-1">{{$nova.formatNumber(userDataSummary.total_users)}}</span>
                <span class="ml-2 line-1">Users<br />Available</span>
              </div>
              <div class="d-flex align-center success--text font-weight-bold ml-6" >
                <span class="display-1">{{ $nova.formatNumber(userDataSummary.active_users) }}</span>
                <span class="ml-2 line-1">Users<br />Active</span>
              </div>
              <div class="d-flex align-center grey--text ml-6">
                <span class="display-1">{{ $nova.formatNumber(userDataSummary.inactive_users) }}</span>
                <span class="ml-2 line-1">Users<br />Inactive</span>
              </div>
              <v-spacer></v-spacer>
              <v-btn class="shadow-off" icon @click="refreshData();"><v-icon>mdi-refresh</v-icon></v-btn>
            </div>
            <lb-datatablenew  :headers="headers" :items="formatedData" :enableslot="['action', 'role', 'status']"
              :loading="loading" multiselectkey="_id" v-if="data.length > 0">
              <template v-slot:role="{ item }">
                <v-chip small label
                  :style="`background-color:${roles[item.role_id]?.color || 'rgba(0,0,0,0.2)'}`">{{roles[item.role_id]?.name
                  || "NA"}}</v-chip>
              </template>
              <template v-slot:status="{ item }">
                <v-chip small label 
                  :class="[(item.status==='Active')?'success':'']">{{item.status}}
                </v-chip>
              </template>
              <template v-slot:action="{ item }">
                <v-tooltip bottom content-class="tooltip-bottom" v-if="item.userid !== userid">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="" @click="editRole(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Update User</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom" v-if="item.userid !== userid">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="" @click="viewUserDetailsdialog=true,openUserRUDetails(item)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>User Details</span>
                </v-tooltip>
              </template>
            </lb-datatablenew>
          </div>
        </v-card-text>
      </v-card>
      
      <lb-dialog v-model="dialog" heading="Change User Role" width="400" :loading="dialogloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1 mb-2">{{selecteduser?.email}} - {{selecteduser?.name}}</div>
            <v-radio-group v-model="selectedrole" class="pt-0 mt-0" dense hide-details>
              <v-radio v-for="(v, i) in roles" :key="i" :label="v.name" class="my-0" :value="i"></v-radio>
            </v-radio-group>

          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="updateUserRoleChange()">Update</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="viewUserDetailsdialog" heading="User RU Access Details" width="1200" :loading="viewUserDetailsdialogloading">
        <template v-slot:body>
          <div>
            <MyReportingUnits :isViewOnly="true" :key="new Date().getTime()" :selectedUserId="selectedUserDetails?.userid"></MyReportingUnits>
          </div>
        </template>
      </lb-dialog>
    </div>
  </div>
</template>

<script>
import MyReportingUnits from './MyReportingUnits.vue';


export default {
  name: 'varienceanalysis_permission_user',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      data: [],
      formatedData: [],
      viewdialog: false,
      viewloading: false,
      viewdata: {},
      dialog: false,
      dialogloading: false,
      selectedrole: "",
      selecteduser: {},
      userid: "",
      roles: {},
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "email",
          text: "Email",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "name",
          text: "User Name",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "role",
          text: "Role",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "status",
          text: "Status",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          filterable: false,
          lock: true,
        },
      ],
      userDataSummary :{},
      viewUserDetailsdialog:false,
      viewUserDetailsdialogloading:false,
      selectedUserDetails:{},
    }
  },
  components: {
    MyReportingUnits
  },
  activated() {
    this.refreshData();
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.userid = this.$store.state.user.id;
      this.getData();
    },
    setLoading(v) {
      this.loading = !!v;
    },
    editRole(user){
      this.selectedrole = user.role_id;
      this.selecteduser = user;
      this.dialog = true;
      this.dialogloading = false;
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/userpermission/getdata").then(dt => {
        if(dt.data.status === "success") {
          this.data = [];
          this.$nextTick(() => {
            this.data = (dt.data.data[0] || {}).data || [];
            let troles = (dt.data.data[0] || {}).roles || [];
            let colors = this.$nova.getColors(troles.length, 0.2);
            for (let i = 0; i < troles.length; i++) {
              const el = troles[i];
              this.roles[el._id] = {name: el.name, color: colors[i]};
            }
            this.userDataSummary = (dt.data.data[0] || {}).summary || {active_users :0,inactive_users :0, total_users :0 }
            this.formateData();
          })
        }else throw new Error ("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateUserRoleChange() {
      this.dialogloading = true;
      this.axios.post("/v2/varianceanalysis/userpermission/edit/"+this.selecteduser._id, {data: {role: [this.selectedrole]}}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess","User permissions updated successfully");
          this.data[this.selecteduser.__key].role = [this.selectedrole];
          this.formatedData = [];
          this.$nextTick(() => {
            this.formateData();
            this.dialog = false;
          });
          // this.ArrayTemp.push({}).pop();
        }else throw new Error ("Error updating role");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.dialogloading = false;
      })
    },
    formateData() {
      let ArrayTemp = [];
      this.data.forEach((i, k) => {
        ArrayTemp.push({
          __key: k,
          __formatedkey: k,
          _id: i._id,
          indexno: k + 1,
          userid: i.userid || "",
          email: i.user_detail?.contactemail || "",
          name: i.user_detail?.name || "NA",
          role_id: i.role[0] || "",
          role: this.roles[i.role[0] || ""]?.name,
          status: i.status===true?'Active':'Inactive'
        });
      });
      // console.log(ArrayTemp)
      this.formatedData = ArrayTemp;
    },
    editData(item){
      this.viewdata = {...item};
    },
    openUserRUDetails(item){
      this.selectedUserDetails = item 
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
/* .subs-icon{
  background-color: var(--v-primary-base) !important;
  color: white;
} */
</style>
